import { useQuery } from '@tanstack/vue-query'

export function getMailBoxQuery() {
  const { $api } = useNuxtApp()
  const profileStore = useProfileStore()
  const { organisationId } = storeToRefs(profileStore)
  const enableQuery = computed(() => !!organisationId.value)

  return useQuery({
    queryKey: ['getMailBox', organisationId.value],
    queryFn: () => $api.banking.mail.getMailBox(organisationId.value!),
    select: (data) => data.data,
    enabled: enableQuery,
  })
}
