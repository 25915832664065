<template>
  <div>
    <div
      v-if="mailBoxData"
      class="flex min-h-10 cursor-pointer items-center gap-x-2 rounded-md border bg-white px-[11px] py-[7px]"
    >
      <div>
        <the-icon icon-name="mail" size="s" class-name="fill-none" />
      </div>

      <div @click.prevent="copyUrl(mailBoxData!.address)">
        <p class="text-xs font-medium text-gray-500">
          Send or forward bills to:
        </p>
        <p class="-mt-1 text-sm font-medium text-primary">
          {{ mailBoxData.address }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheIcon from '@/components/shared/the-icon.vue'
import { useToast } from '@/components/ui/toast/use-toast'
import { getMailBoxQuery } from '~/queries/mail.query'

const { $event } = useNuxtApp()
const { toast } = useToast()

const { data: mailBoxData } = getMailBoxQuery()

const copyUrl = async (items: string) => {
  await navigator.clipboard.writeText(items)
  $event('track:mixpanel', {
    event: 'copy email',
    data: { emailCopied: items },
  })
  toast({
    title: 'Email Copied',
    description: 'Email address has been successfully copied to your clipboard',
    variant: 'default',
  })
}
</script>
